import React from "react";
import { Grid, TextField, FormControl, Select } from "@mui/material";

const InputComponents = ({ size, label = "", onChange, disabled }) => {
  return (
    <Grid item>
      {label}
      <TextField
        onChange={onChange}
        sx={{ mt: "8px" }}
        variant="outlined"
        size={size || "small"}
        fullWidth
        disabled={disabled}
      />
    </Grid>
  );
};

const SelectInput = ({ children, onChange, value, label, disabled }) => {
  return (
    <Grid item container>
      {label}
      <FormControl
        disabled={disabled}
        fullWidth
        size="small"
        sx={{ marginTop: "8px" }}
        value={value}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
        >
          {children}
        </Select>
      </FormControl>
    </Grid>
  );
};

InputComponents.Select = SelectInput;

export default InputComponents;
