import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Grid,
  InputAdornment,
  Button,
  MenuItem,
} from "@mui/material";
import { IconChevronLeft, IconSearch } from "../assets/CustomIcon";
import CustomModal from "../components/CustomModal";
import InputComponents from "../components/InputComponents";
import { makeStyles } from "@mui/styles";
import { Axios } from "../helper/http";
import { useNavigate, useLocation } from "react-router-dom";
import GamesModal from "../components/ProductModal";
import { setToken, getToken } from "../helper/cookies";
import axios from "axios";
import ForbiddenPage from "./ForbiddenPage";

const useStyles = makeStyles(({ breakpoints }) => {
  return {
    Container: {
      padding: { xs: "32px" },
      marginBottom: "120px",
      height: "100%",
      "& h3": {
        // marginTop: 0,
      },
      "& svg": {
        marginRight: "24px",
        color: "#F16F27",
      },
      "& .item-wrapper": {
        marginTop: "32px",
        "& h4": {
          margin: 0,
        },
        "& .header": {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          "& a": {
            textDecoration: "none",
          },
        },
        "& .item": {
          "& img": {
            width: "100%",
            borderRadius: "8%",
          },
          "& h3": {
            [breakpoints.up("md")]: {
              fontSize: "32px",
            },
            fontSize: "12px",
            marginBottom: "4px",
            margin: "0px",
          },
          "& h4": {
            fontSize: "10px",
            margin: 0,
            color: "#F16F27",
            [breakpoints.up("md")]: {
              fontSize: "24px",
            },
          },
        },
      },
    },
  };
});

export default function LandingPage() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [forbidden, setForbidden] = useState(false);

  const Permainan = [
    {
      imgLink:
        "https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY",
      name: "Mobile Legends",
      kategori: "Permainan",
      slug: "mobile-legends",
    },
    // {
    //   imgLink:
    //     "https://upload.wikimedia.org/wikipedia/en/thumb/4/44/PlayerUnknown%27s_Battlegrounds_Mobile.webp/180px-PlayerUnknown%27s_Battlegrounds_Mobile.webp.png",
    //   name: "PUBG : Mobile",
    //   kategori: "Permainan",
    //   slug: "pubg",
    // },
    // {
    //   imgLink:
    //     "https://www.apkmirror.com/wp-content/uploads/2020/04/5e8d68bd21591.png",
    //   name: "Free Fire",
    //   kategori: "Permainan",
    // },
    // {
    //   imgLink:
    //     "https://i.pinimg.com/550x/eb/e4/56/ebe456a7ae0851e9f0d8bd93801a0e94.jpg",
    //   name: "Call Of Duty M...",
    //   kategori: "Permainan",
    // },
    // {
    //   imgLink: "https://apklatestversion.com/logo/apex-legends-mobile-apk.jpg",
    //   name: "Apex Legends...",
    //   kategori: "Permainan",
    // },
    // {
    //   imgLink:
    //     "https://i.pinimg.com/736x/73/32/52/7332527b2baa4785959b1b8e2ba41dce.jpg",
    //   name: "Genshin Impact",
    //   kategori: "Permainan",
    // },
  ];

  const Voucher = [
    {
      imgLink:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/44/PlayerUnknown%27s_Battlegrounds_Mobile.webp/180px-PlayerUnknown%27s_Battlegrounds_Mobile.webp.png",
      name: "PUBG : Mobile",
      kategori: "Voucher",
      slug: "pubg",
    },
    // {
    //   imgLink:
    //     "https://antusiastekno.web.id/wp-content/uploads/2021/02/Steam-Logo.jpg",
    //   name: "Steam Wallet",
    //   kategori: "Voucher",
    // },
    // {
    //   imgLink:
    //     "https://download.logo.wine/logo/Google_Play/Google_Play-Logo.wine.png",
    //   name: "Google Play",
    //   kategori: "Voucher",
    // },
    // {
    //   imgLink:
    //     "https://www.fenesia.com/wp-content/uploads/2021/03/Logo-PlayStation-Store.jpg",
    //   name: "PSN Gift Card",
    //   kategori: "Voucher",
    // },
  ];

  const Entertainment = [
    {
      imgLink:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Netflix_icon.svg/1200px-Netflix_icon.svg.png",
      name: "Netflix",
      kategori: "Entertainment",
    },
    {
      imgLink:
        "https://play-lh.googleusercontent.com/_2geW5_gVcdyHz2s-aVc7EUoN_5Cd0uv_ST8z1x_k4du46VB9JfRfTPFgQFQ1kuJYqc",
      name: "Vidio",
      kategori: "Entertainment",
    },
    {
      imgLink:
        "https://developer.spotify.com/assets/branding-guidelines/icon3@2x.png",
      name: "Spotify",
      kategori: "Entertainment",
    },
  ];

  useEffect(() => {
    const { token } = dataParams;
    if (token) {
      axios
        .post("https://sandbox.api.finpoint.id/rest/auth", null, {
          headers: {
            Authorization: token,
          },
        })
        .then(({ data }) => {
          console.log(data, token);
          const { access_token } = data.data;
          if (access_token) {
            setToken(access_token);
            Axios.get("/coda/token-validation").then(({ data }) => {
              if (data.data.data) {
              } else {
                setForbidden(true);
              }
            });
          } else {
            setForbidden(true);
          }
        });
    } else {
      setForbidden(true);
    }
  }, []);

  if (forbidden) {
    return <ForbiddenPage />;
  } else {
    return (
      <Container className={classes.Container}>
        <h3>
          <IconChevronLeft /> Katalog
        </h3>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch />
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Cari Item"
        />
        <Grid className="item-wrapper">
          <Grid className="header">
            <h4>Permainan</h4>
            <a href="/coda/all-product">Lihat Semua</a>
          </Grid>
          <Grid container mt="16px" spacing="16px" className="body">
            {Permainan.map((val, i) => {
              return (
                <Grid
                  key={i}
                  item
                  xs={4}
                  md={3}
                  onClick={() => setOpen(val)}
                  className="item"
                >
                  <img src={val.imgLink} alt="" />
                  <h3>{val.name}</h3>
                  <h4>{val.kategori}</h4>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid className="item-wrapper">
          <Grid className="header">
            <h4>Voucher</h4>
            <a href="/coda/all-product">Lihat Semua</a>
          </Grid>
          <Grid container mt="16px" spacing="16px" className="body">
            {Voucher.map((val, i) => {
              return (
                <Grid
                  key={i}
                  item
                  xs={4}
                  md={3}
                  onClick={() => setOpen(val)}
                  className="item"
                >
                  <img src={val.imgLink} alt="" />
                  <h3>{val.name}</h3>
                  <h4>{val.kategori}</h4>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {/* <Grid className="item-wrapper">
          <Grid className="header">
            <h4>Entertainment</h4>
            <a href="/all-product">Lihat Semua</a>
          </Grid>
          <Grid container mt="16px" spacing="16px" className="body">
            {Entertainment.map((val, i) => {
              return (
                <Grid
                  key={i}
                  item
                  xs={4}
                  md={3}
                  onClick={() => setOpen(val)}
                  className="item"
                >
                  <img src={val.imgLink} alt="" />
                  <h3>{val.name}</h3>
                  <h4>{val.kategori}</h4>
                </Grid>
              );
            })}
          </Grid>
        </Grid> */}

        {open && <GamesModal open={open} onClose={() => setOpen(false)} />}
      </Container>
    );
  }
}
