import React, { useState } from 'react';
import {
  Container,
  TextField,
  Grid,
  InputAdornment,
  Button,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { IconChevronLeft, IconSearch } from '../assets/CustomIcon';
import CustomModal from '../components/CustomModal';
import InputComponents from '../components/InputComponents';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '../assets/icons/CheckIcon.svg';

export default function PaymentDetail() {
  let navigate = useNavigate();
  const [bayar, setBayar] = useState(false);
  const [open, setOpen] = useState(false);
  const Permainan = [
    {
      imgLink:
        'https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY',
      name: 'Mobile Legends',
      kategori: 'Permainan',
    },
  ];

  return (
    <Container
      sx={(theme) => {
        return {
          padding: { xs: '32px' },
          height: '100vh',
          '& h3': {
            marginTop: 0,
          },
          '& svg': {
            marginRight: '24px',
            color: '#F16F27',
          },
          display: 'flex',
          flexDirection: 'column',
        };
      }}
    >
      <h3>
        <IconButton onClick={() => navigate(-1)}>
          <IconChevronLeft />
        </IconButton>{' '}
        Detail Pembayaran
      </h3>
      <Grid
        sx={{
          padding: '20px',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
        }}
      >
        <Grid
          mt='32px'
          container
          sx={{
            '& img': {
              height: '48px',
              borderRadius: '8px',
              marginRight: '10px',
            },
            '& h5, p': {
              margin: 0,
            },
            '& h5': {
              marginBottom: '8px',
            },
            '& p': {
              fontSize: '12px',
              color: '#A0A0A0',
            },
            alignItems: 'center',
          }}
        >
          <img
            src='https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY'
            alt=''
          />
          <Grid>
            <h5>220 Diamonds Mobile Legends</h5>
            <p>12345678 | 12345678</p>
          </Grid>
        </Grid>
        <Grid
          sx={{
            '& h4': {
              margin: 0,
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '22px',
            },
            '& p': {
              margin: 0,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '16px',
            },
            textAlign: 'center',
            padding: '10px',
            border: bayar ? '1px solid #8DF6C6' : '1px solid #F1A69E',
            marginTop: '32px',
            backgroundColor: bayar ? ' #E1FFF1' : '#FCF3F2',
            color: bayar ? '#0AA06E' : '#DE5242',
            borderRadius: '10px',
            '& .bayar': {
              marginTop: '16px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
            },
          }}
        >
          {bayar ? (
            <>
              <img src={CheckIcon} alt='' />
              <h4 className='bayar'>Pembayaran Berhasil Dilakukan</h4>
            </>
          ) : (
            <>
              <p>Lakukan Pembayaran Sebelum:</p>
              <h4>12 Jam 11 Menit 12 Detik</h4>
            </>
          )}
        </Grid>
        <Grid mt='24px' mb='24px'>
          Bank raya
        </Grid>
        <Grid sx={{ borderTop: '1px dashed #E2E2E2' }} mb='24px' />
        <Grid
          container
          spacing='16px'
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#A0A0A0',
            '& span': {
              fontWeight: 600,
              color: 'black',
            },
          }}
        >
          <Grid container item justifyContent='space-between'>
            Nomor Rekening
            <span>1234578910</span>
          </Grid>
          <Grid container item justifyContent='space-between'>
            Pembayaran
            <span>Rp220.000,00</span>
          </Grid>
          <Grid container item justifyContent='space-between'>
            Biaya Admin
            <span>Rp2500,00</span>
          </Grid>
          <Grid
            sx={{ borderTop: '1px dashed #E2E2E2' }}
            container
            mt='24px'
            mb='24px'
          />
          <Grid container item justifyContent='space-between'>
            Total Pembayaran
            <span>Rp222.500,00</span>
          </Grid>
        </Grid>
      </Grid>
      {!bayar && (
        <Button
          variant='contained'
          sx={{ backgroundColor: '#F16F27', marginTop: 'auto' }}
          fullWidth
          onClick={() => setBayar(true)}
        >
          Bayar
        </Button>
      )}
    </Container>
  );
}
