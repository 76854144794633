import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import LandingPage from "./Pages/LandingPage";
import Payment from "./Pages/Payment";
import PaymentDetail from "./Pages/PaymentDetail";
import AllProduct from "./Pages/AllProduct";
import PreOrder from "./Pages/PreOder";
import GetOrder from "./Pages/GetOrder/GetOrder";
import RedirectPage from "./Pages/RedirectPage";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      textTransform: "none",
    },
  },
});

function App() {
  return (
    // <Dashboard />
    <ThemeProvider theme={theme}>
      <Routes>
        {/* <Route path="/" element={<RedirectPage />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/coda" element={<LandingPage />} />
        <Route path="/coda/all-product" element={<AllProduct />} />
        <Route path="/coda/payment" element={<Payment />} />
        <Route path="/coda/payment/:token" element={<PaymentDetail />} />
        <Route path="/coda/pre-order" element={<PreOrder />} />
        <Route path="/coda/get-order" element={<GetOrder />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
