export const GENERATE_PULUHAN = (nominal) => {
  var angka = Number(nominal);
  var rounded = angka.toFixed(2);
  var number_string = rounded.toString(),
    separator = "",
    split = number_string.split("."),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

  if (ribuan) {
    separator = sisa ? "," : "";
    rupiah += separator + ribuan.join(",");
  }
  rupiah = split[1] !== undefined ? rupiah + "." + split[1] : rupiah;
  return rupiah;
  //   return nominal;
};
