import React, { useState } from "react";
import {
  Container,
  TextField,
  Grid,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import { IconChevronLeft, IconSearch } from "../assets/CustomIcon";
import CustomModal from "../components/CustomModal";
import InputComponents from "../components/InputComponents";
import { useNavigate } from "react-router-dom";

export default function AllProduct() {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const Permainan = [
    {
      imgLink:
        "https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY",
      name: "Mobile Legends",
      kategori: "Permainan",
    },
    {
      imgLink:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/44/PlayerUnknown%27s_Battlegrounds_Mobile.webp/180px-PlayerUnknown%27s_Battlegrounds_Mobile.webp.png",
      name: "PUBG : Mobile",
      kategori: "Permainan",
    },
    {
      imgLink:
        "https://www.apkmirror.com/wp-content/uploads/2020/04/5e8d68bd21591.png",
      name: "Free Fire",
      kategori: "Permainan",
    },
    {
      imgLink:
        "https://i.pinimg.com/550x/eb/e4/56/ebe456a7ae0851e9f0d8bd93801a0e94.jpg",
      name: "Call Of Duty M...",
      kategori: "Permainan",
    },
    {
      imgLink: "https://apklatestversion.com/logo/apex-legends-mobile-apk.jpg",
      name: "Apex Legends...",
      kategori: "Permainan",
    },
    {
      imgLink:
        "https://i.pinimg.com/736x/73/32/52/7332527b2baa4785959b1b8e2ba41dce.jpg",
      name: "Genshin Impact",
      kategori: "Permainan",
    },
  ];

  return (
    <Container
      sx={(theme) => {
        return {
          padding: { xs: "32px" },
          height: "100%",
          "& h3": {
            marginTop: 0,
          },
          "& svg": {
            marginRight: "24px",
            color: "#F16F27",
          },
        };
      }}
    >
      <h3>
        <IconButton onClick={() => navigate(-1)}>
          <IconChevronLeft />
        </IconButton>{" "}
        Permainan
      </h3>
      <Grid
        sx={{
          "& h4": {
            margin: 0,
          },
        }}
        mt="32px"
      >
        {/* <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h4>Permainan</h4>
          Lihat Semua
        </Grid> */}
        <Grid
          container
          mt="16px"
          spacing="16px"
          sx={{
            "& img": {
              width: "100%",
              borderRadius: "8%",
            },
            "& h3": {
              fontSize: { xs: "12px", marginBottom: "4px" },
            },
            "& h4": {
              fontSize: { xs: "10px", margin: 0, color: "#F16F27" },
            },
          }}
        >
          {Permainan.map((val) => {
            return (
              <Grid item xs={4} onClick={() => setOpen(val)}>
                <img src={val.imgLink} alt="" />
                <h3>{val.name}</h3>
                <h4>{val.kategori}</h4>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {open && (
        <CustomModal
          title="Detail Permainan"
          open={open}
          onClose={() => setOpen(false)}
          width="90%"
        >
          <Grid
            sx={{
              "& hr": {
                marginTop: "24px",
                marginBottom: "24px",
              },
            }}
          >
            <Grid
              mt="36px"
              container
              sx={{
                display: "flex",
                alignItems: "center",
                "& img": {
                  height: "32px",
                  borderRadius: "8px",
                },
                "& h4": {
                  margin: 0,
                  marginLeft: "10px",
                },
              }}
            >
              <img src={open?.imgLink} alt="" />
              <h4>{open?.name}</h4>
            </Grid>
            <Grid mt="0px" container spacing="24px">
              <InputComponents label="ID Pengguna" />
              <InputComponents label="ID Server" />
              <InputComponents.Select />
            </Grid>
            <hr />
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                marginBottom: "24px",
                "& h4": { margin: 0 },
              }}
            >
              Harga
              <h4>Rp20.000,00</h4>
            </Grid>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#F16F27" }}
              fullWidth
              href="/payment"
            >
              Beli
            </Button>
          </Grid>
        </CustomModal>
      )}
    </Container>
  );
}
