import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../helper/http";
import {
  Grid,
  MenuItem,
  Button,
  Collapse,
  Alert,
  CircularProgress,
} from "@mui/material";
import InputComponents from "../InputComponents";
import { GENERATE_PULUHAN } from "../../helper/GlobalFunction";

export default function MobileLegendsModal({ open, onClose }) {
  const navigate = useNavigate();
  const [datas, setDatas] = useState([1, 2, 3]);
  const [userData, setUserData] = useState({});
  const [dataSelect, setDataSelect] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getDetailProduct = () => {
    setLoading(true);
    Axios.get(`/coda/${open?.slug}/list-sku`)
      .then((res) => {
        setDatas(res.data.data.result.skuList);
        setLoading(false);
      })
      .catch((err) => setError(err));
  };

  useEffect(() => {
    if (Boolean(open)) {
      getDetailProduct();
    }
  }, []);

  const handleSubmit = () => {
    const values = {
      items: [{ ...dataSelect, quantity: 1 }],
      customerId: userData?.pengguna,
      userAccount: userData?.server,
    };
    setLoading(true);
    Axios.post("/coda/mobile-legends/validate", values)
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        navigate("/coda/pre-order", {
          state: { ...res.data.data, slug: open?.slug, dataItems: dataSelect },
        });
      })
      .catch((err) => setError(err));
  };

  return (
    <CustomModal
      title="Detail Permainan"
      open={Boolean(open)}
      onClose={onClose}
      width={{ xs: "90%", md: "1200px" }}
    >
      <Grid
        sx={{
          "& hr": {
            marginTop: "24px",
            marginBottom: "24px",
          },
        }}
      >
        {Boolean(error) && (
          <Alert severity="error" sx={{ width: "90%" }}>
            {/* {JSON.stringify(error, null, 4)} */}
            {error?.message}
          </Alert>
        )}
        <Grid
          mt="36px"
          container
          sx={{
            display: "flex",
            alignItems: "center",
            "& img": {
              height: "32px",
              borderRadius: "8px",
            },
            "& h4": {
              margin: 0,
              marginLeft: "10px",
            },
          }}
        >
          <img src={open?.imgLink} alt="" />
          <h4>{open?.name}</h4>
        </Grid>
        {loading && (
          <Grid mt="24px">
            <CircularProgress />
          </Grid>
        )}
        <Collapse in={!loading}>
          <Grid mt="0px" container spacing="24px">
            <InputComponents
              label="ID Pengguna"
              onChange={({ target }) =>
                setUserData({ ...userData, pengguna: target.value })
              }
              disabled={loading}
            />
            <InputComponents
              label="ID Server"
              onChange={({ target }) =>
                setUserData({ ...userData, server: target.value })
              }
              disabled={loading}
            />
            <InputComponents.Select
              onChange={(e) => setDataSelect(e.target.value)}
              label="Jumlah Diamond"
              value={dataSelect || ""}
              disabled={loading}
            >
              {datas.map((res, i) => {
                return (
                  <MenuItem key={i} value={res}>
                    {res.description}
                  </MenuItem>
                );
              })}
            </InputComponents.Select>
          </Grid>
          <hr />
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              marginBottom: "24px",
              "& h4": { margin: 0 },
            }}
          >
            Harga
            <h4>Rp{GENERATE_PULUHAN(dataSelect?.price?.amount || 0)}</h4>
          </Grid>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#F16F27" }}
            fullWidth
            onClick={handleSubmit}
          >
            Beli
          </Button>
        </Collapse>
      </Grid>
    </CustomModal>
  );
}
