import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Grid,
  InputAdornment,
  Button,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { IconChevronLeft, IconSearch } from '../assets/CustomIcon';
import CustomModal from '../components/CustomModal';
import InputComponents from '../components/InputComponents';
// import { useHistory } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import BankRayaLogo from '../assets/img/bank-raya-logo.png';

export default function Payment() {
  let params = useLocation()
  let navigate = useNavigate();
  const [state, setState] = useState(null)

  const [open, setOpen] = useState(false);
  const Permainan = [
    {
      imgLink:
        'https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY',
      name: 'Mobile Legends',
      kategori: 'Permainan',
    },
  ];

  useEffect(() => {
    setState(params.state)
  }, [])

  console.log(state)

  return (
    <Container
      sx={(theme) => {
        return {
          padding: { xs: '32px' },
          height: '100vh',
          '& h3': {
            marginTop: 0,
          },
          '& svg': {
            marginRight: '24px',
            color: '#F16F27',
          },
          '& hr': {
            marginBottom: '24px',
            marginTop: '24px',
            border: '1px solid #EAEDF2',
          },
          display: 'flex',
          flexDirection: 'column',
        };
      }}
    >
      <Grid>
        <h3>
          <IconButton onClick={() => navigate(-1)}>
            <IconChevronLeft />
          </IconButton>
          Konfirmasi Pembayaran
        </h3>
        <Grid
          mt='32px'
          container
          sx={{
            '& img': {
              height: '48px',
              borderRadius: '8px',
              marginRight: '10px',
            },
            '& h5, p': {
              margin: 0,
            },
            '& h5': {
              marginBottom: '8px',
            },
            '& p': {
              fontSize: '12px',
              color: '#A0A0A0',
            },
            alignItems: 'center',
          }}
        >
          <img
            src='https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY'
            alt=''
          />
          <Grid>
            <h5>{state?.description || "-"}</h5>
            <p>{state?.pengguna}({state?.server})</p>
          </Grid>
        </Grid>
        <hr />
        <Grid
          sx={{
            '& h5': {
              margin: 0,
            },
          }}
        >
          {/* <h5>Pilih Metode Pembayaran</h5>
          <Autocomplete
            disablePortal
            options={[{ label: "The Shawshank Redemption", year: 1994 }]}
            onChange={(a, b) => console.log(a, b)}
            renderOption={(props, option) => {
              return <Grid {...props}>{option.label}</Grid>;
            }}
            id="combo-box-demo"
            sx={{
              "& svg": {
                marginRight: "0px",
              },
              marginTop: "16px",
            }}
            size="small"
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <Autocomplete
            disablePortal
            options={[{ label: "The Shawshank Redemption", year: 1994 }]}
            onChange={(a, b) => console.log(a, b)}
            renderOption={(props, option) => {
              return <Grid {...props}>{option.label}</Grid>;
            }}
            id="combo-box-demo"
            sx={{
              "& svg": {
                marginRight: "0px",
              },
              marginTop: "16px",
            }}
            size="small"
            renderInput={(params) => <TextField size="small" {...params} />}
          /> */}
          <Grid
            container
            mt='24px'
            justifyContent='space-between'
            sx={{
              '& span': {
                color: 'black',
                justifyContent: 'center',
                display: 'flex',
                '& img': {
                  marginRight: '12px',
                },
              },
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#A0A0A0',
            }}
          >
            Metode Pembayaran
            <span>
              <img src={BankRayaLogo} alt='' />
              Bank Raya
            </span>
          </Grid>
          <Grid
            container
            mt='24px'
            justifyContent='space-between'
            sx={{
              '& h4': {
                margin: 0,
                color: 'black',
              },
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#A0A0A0',
            }}
          >
            Total Pembayaran
            <span>
              <h4>Rp{state?.price?.amount || 0}</h4>
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant='contained'
        sx={{ backgroundColor: '#F16F27', marginTop: 'auto' }}
        fullWidth
        href='/payment/test'
      >
        Lanjutkan
      </Button>
    </Container>
  );
}
