import React from "react";
import MobileLegendsModal from "./MobileLegendsModal";
import PubgModal from "./PUBGModal";

export default function GamesModal({ open, onClose }) {
  const { slug, kategori } = open;
  if (kategori === "Permainan") {
    if (slug === "mobile-legends") {
      return <MobileLegendsModal open={open} onClose={onClose} />;
    } else if (slug === "pubg") {
    }
  } else if (kategori === "Voucher") {
    if (slug === "pubg") {
      return <PubgModal open={open} onClose={onClose} />;
    }
  }
}
