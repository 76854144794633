import axios from "axios";
import { setToken, getToken } from "./cookies";

const URL_API = "https://sandbox.api.finpoint.id/rest/";

export const Axios = axios.create({
  baseURL: URL_API,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});
export const api = axios.create({
  baseURL: URL_API,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});
api.interceptors.request.use(
  (config) => {
    const tokens = getToken();
    if (tokens) {
      config.headers["Authorization"] = `Bearer ${tokens}`;
    }
    return config;
  },
  (error) => {
    //console.log(error)
    Promise.reject(error);
  }
);

Axios.interceptors.request.use(
  (config) => {
    const tokens = getToken();
    if (tokens) {
      config.headers["Authorization"] = `Bearer ${tokens}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    const originalRequest = error.config;
    console.log(error);
    if (error.response !== undefined) {
      if (error.response.status === 401 && !originalRequest._retry) {
        // console.log(originalRequest);
        // // originalRequest._retry = true;
        // const res = await Axios.post(
        //   `https://sandbox.api.finpoint.id/rest/auth`,
        //   null,
        //   {
        //     headers: {
        //       Authorization: "LDAP Zmlrcmk6RjFrcmkyMDIy",
        //     },
        //   }
        // );
        // if (res.status === 200) {
        //   setToken(res.data.data.access_token);
        //   axios.defaults.headers.common[
        //     "Authorization"
        //   ] = `Bearer ${getToken()}`;
        //   return axios(originalRequest);
        // } else {
        //   if (
        //     error.response.status === 401 &&
        //     originalRequest.url === `https://sandbox.api.finpoint.id/rest/auth`
        //   ) {
        //     return Promise.reject(error);
        //   } else {
        //     return Promise.reject(error);
        //   }
        // }
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export const errorHandler = (error) => {
  console.log(error.response.data);
  if (error.response && error.response.data.errors) {
    if (error.response.status === 409) {
      // return new Error("Resource already exists")
      // Swal.fire("Oops!", "Resource already exists", "error")
    }
    let strError = "";
    for (const err of error.response.data.errors) {
      strError += err.param + " : " + err.msg + "<br/>";
    }
    // Swal.fire("Oops!", strError, "error")
    // return new Error(strError)
  } else if (error.response) {
    if (error.response.data) {
      // Swal.fire("Oops!", error.response.data.message, "error")
    } else {
      // Swal.fire("Oops!", "Error", "error")
    }
  } else {
    return error;
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default { Axios, errorHandler };
