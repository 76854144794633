import React from "react";
import { useLocation } from "react-router-dom";
import PUBGGetOrder from "./PUBGGetOrder";
import MobileLegendsGetOrder from "./MobileLegendsGetOrder";

export default function GetOrder() {
  const { search } = useLocation();
  const dataParams = Object.fromEntries(new URLSearchParams(search));
  const { type, name } = dataParams;

  if (type === "game") {
    if (name === "pubg") {
      return <PUBGGetOrder dataParams={dataParams} />;
    } else if (name === "mobile-legends") {
      return <MobileLegendsGetOrder dataParams={dataParams} />;
    }
  }
}
