import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Grid,
  InputAdornment,
  Button,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { IconChevronLeft, IconSearch } from "../../assets/CustomIcon";
import { useNavigate } from "react-router-dom";
import CheckIcon from "../../assets/icons/CheckIcon.svg";
import { GENERATE_PULUHAN } from "../../helper/GlobalFunction";
import { Axios } from "../../helper/http";
import { createSearchParams } from "react-router-dom";

export default function PUBGGetOrder({ state, dataParams }) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dataProduct, setDataProduct] = useState();
  const [items, setItems] = useState({});

  useEffect(() => {
    getDataProduct();
  }, []);

  const getDataProduct = async () => {
    const response = await Axios.post("/coda/pubg/get-order", {
      requestId: dataParams?.requestId,
    });
    const { data } = response.data;
    if (data.data.items.length > 0) {
      setDataProduct(data);
      setItems(data.data.items[0]);
    }
  };

  return (
    <Container
      sx={(theme) => {
        return {
          padding: { xs: "32px" },
          height: "100vh",
          "& h3": {
            marginTop: 0,
          },
          "& svg": {
            marginRight: "24px",
            color: "#F16F27",
          },
          display: "flex",
          flexDirection: "column",
        };
      }}
    >
      <h3>
        <IconButton onClick={() => navigate(-1)}>
          <IconChevronLeft />
        </IconButton>{" "}
        Status Pembayaran
      </h3>
      <Grid
        sx={{
          padding: "20px",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <Grid
          sx={{ "& h5": { margin: 0 } }}
          container
          justifyContent="flex-end"
        >
          <h5>{dataProduct?.status_desc}</h5>
        </Grid>
        <Grid
          mt="32px"
          container
          sx={{
            "& img": {
              height: "48px",
              borderRadius: "8px",
              marginRight: "10px",
            },
            "& h5, p": {
              margin: 0,
            },
            "& h5": {
              marginBottom: "8px",
            },
            "& p": {
              fontSize: "12px",
              color: "#A0A0A0",
            },
            alignItems: "center",
          }}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/44/PlayerUnknown%27s_Battlegrounds_Mobile.webp/180px-PlayerUnknown%27s_Battlegrounds_Mobile.webp.png"
            alt=""
          />
          <Grid>
            <h5>{dataProduct?.data?.items?.pop()?.sku}</h5>
            <p>{dataProduct?.data?.customerId}</p>
          </Grid>
        </Grid>
        <Grid mt="24px" mb="24px">
          Bank raya
        </Grid>
        <Grid sx={{ borderTop: "1px dashed #E2E2E2" }} mb="24px" />
        <Grid
          container
          spacing="16px"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#A0A0A0",
            "& span": {
              fontWeight: 600,
              color: "black",
            },
          }}
        >
          <Grid container item justifyContent="space-between">
            Nomor Rekening
            <span>-</span>
          </Grid>
          <Grid container item justifyContent="space-between">
            Pembayaran
            <span>Rp.{GENERATE_PULUHAN(items?.price?.amount)}</span>
          </Grid>
          <Grid container item justifyContent="space-between">
            Biaya Admin
            <span>-</span>
          </Grid>
          <Grid
            sx={{ borderTop: "1px dashed #E2E2E2" }}
            container
            mt="24px"
            mb="24px"
          />
          <Grid container item justifyContent="space-between">
            Total Pembayaran
            <span>Rp.{GENERATE_PULUHAN(items?.price?.amount)}</span>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
