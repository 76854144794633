import React from 'react';
import { Modal, Grid, IconButton } from '@mui/material';
import { IconCross } from '../assets/CustomIcon';

export default function CustomModal({
  width,
  height,
  children,
  open,
  onClose,
  title,
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: height ? height : 'auto',
          width: width ? width : 'auto',
          bgcolor: '#FFFFFF',
          p: '24px',
          borderRadius: '10px',
          '& h1, h2, h3': {
            margin: 0,
          },
          '& h3': {
            marginBottom: '12px',
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',
          },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: title ? 'space-between' : 'flex-end',
            '& h4': {
              margin: 0,
            },
          }}
        >
          {title && <h4>{title}</h4>}
          <IconButton onClick={onClose}>
            <IconCross />
          </IconButton>
        </Grid>
        {children}
      </Grid>
    </Modal>
  );
}
