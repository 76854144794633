import React from "react";
import { Grid } from "@mui/material";

export default function ForbiddenPage() {
  return (
    <Grid
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          textAlign: "center",
          "& h1": {
            fontSize: "80px",
            margin: 0,
          },
          "& h2": {
            fontSize: "20px",
            margin: 0,
          },
        }}
      >
        <h1>403</h1>
        <h2>Forbidden</h2>
        <h5>you don't have permission to access this resource.</h5>
      </Grid>
    </Grid>
  );
}
