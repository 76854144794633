import React, { useState } from "react";
import {
  Container,
  TextField,
  Grid,
  InputAdornment,
  Button,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { IconChevronLeft, IconSearch } from "../../assets/CustomIcon";
import { useNavigate } from "react-router-dom";
import CheckIcon from "../../assets/icons/CheckIcon.svg";
import { GENERATE_PULUHAN } from "../../helper/GlobalFunction";
import { Axios } from "../../helper/http";
import { createSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function PreOderMobileLegends({ state }) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleBayar = () => {
    const values = {
      orderId: state?.data?.orderId,
      items: [{ ...state?.dataItems, quantity: 1 }],
      customerId: state?.data?.customerId,
      userAccount: state?.data?.userAccount,
    };

    Axios.post("/coda/mobile-legends/pre-order", values).then((res) => {
      console.log(res);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Berhasil melakukan PreOrder",
      }).then((val) => {
        navigate({
          pathname: "/coda/get-order",
          search: createSearchParams({
            type: "game",
            name: "mobile-legends",
            requestId: res.data.data.data.requestId,
          }).toString(),
        });
      });
    });
  };

  console.log(state);

  return (
    <Container
      sx={(theme) => {
        return {
          padding: { xs: "32px" },
          height: "100vh",
          "& h3": {
            marginTop: 0,
          },
          "& svg": {
            marginRight: "24px",
            color: "#F16F27",
          },
          display: "flex",
          flexDirection: "column",
        };
      }}
    >
      <h3>
        <IconButton onClick={() => navigate(-1)}>
          <IconChevronLeft />
        </IconButton>{" "}
        Detail Pembayaran
      </h3>
      <Grid
        sx={{
          padding: "20px",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <Grid
          mt="32px"
          container
          sx={{
            "& img": {
              height: "48px",
              borderRadius: "8px",
              marginRight: "10px",
            },
            "& h5, p": {
              margin: 0,
            },
            "& h5": {
              marginBottom: "8px",
            },
            "& p": {
              fontSize: "12px",
              color: "#A0A0A0",
            },
            alignItems: "center",
          }}
        >
          <img
            src="https://play-lh.googleusercontent.com/IhUVzRkz5MokalaeiLulcloc8rxROw0fSPRC7-Lc5zCF_wIfhbxo3qsOjKxYp524B1dY"
            alt=""
          />
          <Grid>
            <h5>{state?.dataItems?.description} Mobile Legends</h5>
            <p>
              {state?.data?.customerId} | {state?.data?.userAccount}
            </p>
            <p>({state?.data?.username})</p>
          </Grid>
        </Grid>
        <Grid mt="24px" mb="24px">
          Bank raya
        </Grid>
        <Grid sx={{ borderTop: "1px dashed #E2E2E2" }} mb="24px" />
        <Grid
          container
          spacing="16px"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#A0A0A0",
            "& span": {
              fontWeight: 600,
              color: "black",
            },
          }}
        >
          <Grid container item justifyContent="space-between">
            Nomor Rekening
            <span>-</span>
          </Grid>
          <Grid container item justifyContent="space-between">
            Pembayaran
            <span>Rp{GENERATE_PULUHAN(state?.dataItems?.price?.amount)}</span>
          </Grid>
          <Grid container item justifyContent="space-between">
            Biaya Admin
            <span>-</span>
          </Grid>
          <Grid
            sx={{ borderTop: "1px dashed #E2E2E2" }}
            container
            mt="24px"
            mb="24px"
          />
          <Grid container item justifyContent="space-between">
            Total Pembayaran
            <span>Rp{GENERATE_PULUHAN(state?.dataItems?.price?.amount)}</span>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ backgroundColor: "#F16F27", marginTop: "auto" }}
        fullWidth
        onClick={handleBayar}
      >
        Pre Order
      </Button>
    </Container>
  );
}
