export const IconChevronLeft = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m2.828 7 4.95 4.95-1.414 1.414L0 7 6.364.636 7.778 2.05 2.828 7Z"
      fill="currentColor"
    />
  </svg>
);

export const IconSearch = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m14.026 12.848 3.569 3.568-1.18 1.179-3.568-3.57a7.466 7.466 0 0 1-4.68 1.642c-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 4.14 0 7.5 3.36 7.5 7.5a7.467 7.467 0 0 1-1.641 4.68Zm-1.672-.619A5.814 5.814 0 0 0 14 8.167a5.832 5.832 0 0 0-5.834-5.834 5.831 5.831 0 0 0-5.833 5.834A5.832 5.832 0 0 0 8.166 14a5.814 5.814 0 0 0 4.063-1.646l.125-.125Z"
      fill="currentColor"
    />
  </svg>
);

export const IconCross = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7 5.586 4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.414 1.414L7 8.414l-4.95 4.95L.636 11.95 5.586 7 .636 2.05 2.05.636 7 5.586Z"
      fill="currentColor"
    />
  </svg>
);
