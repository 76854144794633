import Cookie from "js-cookie";

export function getToken() {
  const tokens = Cookie.get("token");
  return tokens;
}

export function getUser() {
  return Cookie.getJSON("user");
}

export function setToken(data) {
  Cookie.set("token", data);
}

export function setUser(data) {
  Cookie.set("user", {
    email: data.email,
    id: data.id,
    roleID: data.roleId,
    name: data.name,
  });
}

export function getRole() {
  const user = getUser();
  if (user) {
    return user.roleID;
  }
  return null;
}
